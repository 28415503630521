import React from 'react';
import {CurrenciesDropdown} from '../CurrenciesDropdown/CurrenciesDropdown';
import {IPropsInjectedByViewerScript} from '../../viewerScript/currencyConverterStore';

export class CurrencyConverterApp extends React.Component<IPropsInjectedByViewerScript> {
  public componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  private readonly reportAppLoaded = () => {
    /* istanbul ignore next: hard to test it */
    if (this.props.selectedCurrency && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  };

  public render() {
    const {
      userCurrencies,
      currencySymbols,
      selectedCurrency,
      onCurrencySelected,
      translations,
      isRtl,
      isMobile,
      host: {dimensions},
    } = this.props;
    return (
      <CurrenciesDropdown
        style={{height: dimensions.height}}
        currencySymbols={currencySymbols}
        currencies={userCurrencies}
        onChange={onCurrencySelected}
        selectedCurrency={selectedCurrency}
        translations={translations}
        isRtl={isRtl}
        isMobile={isMobile}
      />
    );
  }
}
